import axios from "axios";
import { Message } from "element-ui";
// import { MessageBox, Message } from 'element-ui'
import store from "@/store";

import Router from "../router/index.js";

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000, // request timeout
});

service.interceptors.response.use(
  (response) => {
    const { data } = response;
    return data;
  },
  (error) => {
    console.log(error);
  }
);

export default service;
