import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/menu',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/menu',
    name: 'menu',
    component: () => import('../views/Menu.vue'),
  },
  {
    path: '/facebook',
    name: 'facebook',
    meta: {
      id: 1,
      name: 'facebook',
      icon: 'el-icon-s-claim',
    },
    component: () => import('../views/ModelView3.vue'),
    children: [
      // {
      //   path: "/facebook/keywords",
      //   meta: {
      //     name: "首页",
      //   },
      //   component: () => import("../views/facebook/B2.vue"),
      // },
      {
        path: '/facebook/posts',
        meta: {
          name: '帖子数据',
        },
        component: () => import('../views/facebook/B4.vue'),
      },
      {
        path: '/facebook/account',
        meta: {
          name: '落查',
        },
        component: () => import('../views/facebook/B3.vue'),
      },
    ],
  },
  {
    path: '/gmail',
    name: 'gmail',
    meta: {
      id: 2,
      name: 'gmail',
      icon: 'el-icon-magic-stick',
    },
    component: () => import('../views/ModelView.vue'),
    children: [
      {
        path: '/gmail/data',
        name: 'ModelView2',
        meta: {
          name: '账号落查',
          data: {
            title: '1-1shegongshuju',
          },
        },
        component: () => import('../views/page/menu/GlobalData.vue'),
      },
    ],
  },
  {
    path: '/WhatsApp',
    name: 'WhatsApp',
    meta: {
      id: 3,
      name: 'WhatsApp',
      icon: 'el-icon-s-grid',
    },
    component: () => import('../views/ModelView.vue'),
    children: [
      {
        path: '/whatsapp/index',
        meta: {
          name: 'whatsapp头像数据',
          data: {
            title: '1-2touxiangtupianshuju',
          },
        },
        component: () => import('../views/page/menu/GlobalData.vue'),
      },

      {
        path: '/whatsapp/chat',
        meta: {
          name: 'WhatsApp用户数据',
          data: {
            title: '4-2whatsapp',
          },
        },
        component: () => import('../views/page/menu/GlobalData.vue'),
      },
      {
        path: '/whatsapp/system',
        meta: {
          name: 'WhatsApp系统',
          data: {
            title: 'WhatsApp系统',
          },
        },
        component: () => import('../views/whatsapp/Index.vue'),
      },
    ],
  },
  {
    path: '/linkedin',
    name: 'linkedin',
    meta: {
      id: 4,
      name: 'linkedin',
      icon: 'el-icon-s-shop',
    },
    component: () => import('../views/ModelView.vue'),
    children: [
      {
        path: '/linkedin/index',
        meta: {
          name: 'linkedin落查数据',
          data: {
            title: '1-1shegongshuju',
          },
        },
        component: () => import('../views/page/menu/GlobalData.vue'),
      },
    ],
  },
  {
    path: '/myspace',
    name: 'myspace',
    meta: {
      id: 5,
      name: 'myspace',
      icon: 'el-icon-takeaway-box',
    },
    component: () => import('../views/ModelView.vue'),
    children: [
      {
        path: '/myspace/index',
        meta: {
          name: 'MySpace落查数据',
          data: {
            title: '1-1shegongshuju',
          },
        },
        component: () => import('../views/page/menu/GlobalData.vue'),
      },
      {
        path: '/myspace/guanlian',
        meta: {
          name: 'MySpace虚实关联数据',
          data: {
            title: '1-3xushiguanlianshuju',
          },
        },
        component: () => import('../views/page/menu/GlobalData.vue'),
      },
    ],
  },
  {
    path: '/line',
    name: 'line',
    meta: {
      id: 6,
      name: 'line',
      icon: 'el-icon-mobile',
    },
    component: () => import('../views/ModelView.vue'),
    children: [
      {
        path: '/line/index',
        meta: {
          name: 'Line注册信息获取',
          data: {
            title: '5-2Line',
          },
        },
        component: () => import('../views/page/menu/GlobalData.vue'),
      },
    ],
  },
  {
    path: '/analysis',
    name: 'analysis',
    meta: {
      id: 7,
      name: '数据分析',
      icon: 'el-icon-s-data',
    },
    component: () => import('../views/ModelView.vue'),
    children: [
      {
        path: '/analysis/one',
        meta: {
          name: '联机模式',
        },
        component: () => import('../views/analysis/One.vue'),
      },
      {
        path: '/analysis/two',
        meta: {
          name: '侦察流程',
        },
        component: () => import('../views/analysis/Two.vue'),
      },
    ],
  },
  {
    path: '/dark',
    name: '暗网',
    meta: {
      id: 8,
      name: '暗网',
      icon: 'el-icon-s-finance',
    },
    component: () => import('../views/ModelView.vue'),
    children: [
      {
        path: '/dark/net',
        meta: {
          name: '暗网',
          data: {
            title: '4-5shenwang',
          },
        },
        component: () => import('../views/page/menu/GlobalData.vue'),
      },
    ],
  },
  {
    path: '/instagram',
    name: 'instagram',
    meta: {
      id: 9,
      name: 'Instagram',
      icon: 'el-icon-edit',
    },
    component: () => import('../views/ModelView.vue'),
    children: [
      {
        path: '/instagram/keywords',
        meta: {
          name: '落查',
          data: {
            title: '5-4ins',
          },
        },
        component: () => import('../views/page/menu/GlobalData.vue'),
      },
    ],
  },

  {
    path: '/pinterest',
    name: 'pinterest',
    meta: {
      id: 10,
      name: 'Pinterest',
      icon: 'el-icon-monitor',
    },
    component: () => import('../views/ModelView.vue'),
    children: [
      {
        path: '/pinterest/keywords',
        meta: {
          name: '落查',
          data: {
            title: '5-4ins',
          },
        },
        component: () => import('../views/page/menu/GlobalData.vue'),
      },
    ],
  },
  {
    path: '/vimeo',
    name: 'vimeo',
    meta: {
      id: 11,
      name: 'vimeo',
      icon: 'el-icon-paperclip',
    },
    component: () => import('../views/ModelView.vue'),
    children: [
      {
        path: '/vimeo/keywords',
        meta: {
          name: '落查',
          data: {
            title: '5-5youtube',
          },
        },
        component: () => import('../views/page/menu/GlobalData.vue'),
      },
    ],
  },
  // -----------------------------------
  {
    path: '/big/data/:type',
    name: 'BigDataType',
    component: () => import('../views/page/BigDataOnly.vue'),
  },
  {
    path: '/data/date/:type',
    name: 'BigDataDate',
    component: () => import('../views/page/BigDataDate.vue'),
  },
  {
    path: '/data/date5/:type',
    name: 'BigDataDate5',
    component: () => import('../views/page/BigDataDate5.vue'),
  },
  {
    path: '/data/check/:type',
    name: 'BigCheck',
    component: () => import('../views/page/BigCheck.vue'),
  },
  // 人口数据页面
  {
    path: '/big/data6',
    name: 'BigDataType6',
    component: () => import('../views/page/BigDataOnly6.vue'),
  },
  // facebook采集
  {
    path: '/big/data7/:type',
    name: 'BigDataType7',
    component: () => import('../views/page/BigDataOnly7.vue'),
  },
  // -----------------------------------
  {
    path: '/big/data',
    name: 'ModelView4',
    component: () => import('../views/ModelView4.vue'),
  },
  // 四合一
  {
    path: '/entry',
    name: 'loginIndex',
    component: () => import('../views/collection/Login.vue'),
  },
  {
    path: '/web/check/:type',
    name: 'webCheck',
    component: () => import('../views/collection/WebCheck.vue'),
  },
  {
    path: '/web/data/:type',
    name: 'webdata',
    component: () => import('../views/collection/WebData.vue'),
  },
  // line系统落查系统
  {
    path: '/system/line/index',
    name: 'lineIndex',
    component: () => import('@/views/system/line/Index'),
    redirect: '/system/line/home',
    children: [
      {
        path: '/system/line/home',
        name: 'systemLineHome',
        component: () => import('../views/system/line/pages/Home.vue'),
      },
      {
        path: '/system/line/setting',
        name: 'systemLineSetting',
        component: () => import('../views/system/line/pages/setting.vue'),
      },
      {
        path: '/system/line/search',
        name: 'systemLineSerach',
        component: () => import('../views/system/line/pages/search.vue'),
      },
      // {
      //   path: '/system/line/data/:type', //落查
      //   name: 'systemLineData',
      //   component: () => import('../views/system/components/WebCheck.vue'),
      // },
      {
        path: '/system/line/logs',
        name: 'systemLineLog',
        component: () => import('../views/system/line/pages/Logs.vue'),
      },
    ],
  },
  //facebook 网站落查系统
  {
    path: '/system/face/index',
    name: 'faceIndex',
    component: () => import('@/views/system/face/index'),
    redirect: '/system/face/home',
    children: [
      {
        path: '/system/face/home',
        name: 'systemfaceHome',
        component: () => import('../views/system/face/pages/Home.vue'),
      },
      {
        path: '/system/face/setting',
        name: 'systemfaceSetting',
        component: () => import('../views/system/face/pages/setting.vue'),
      },
      {
        path: '/system/face/search',
        name: 'systemfaceSearch',
        component: () => import('../views/system/face/pages/search.vue'),
      },
      {
        path: '/system/face/logs',
        name: 'systemfaceLogs',
        component: () => import('../views/system/face/pages/logs.vue'),
      },
    ],
  },

  //媒体软件落查 其他网站
  {
    path: '/system/media/index',
    name: 'mediaIndex',
    component: () => import('@/views/system/media/index'),
    redirect: '/system/media/home',
    children: [
      {
        path: '/system/media/home',
        name: 'systemMediaHome',
        component: () => import('../views/system/media/pages/Home.vue'),
      },
      {
        path: '/system/media/setting',
        name: 'systemMediaSetting',
        component: () => import('../views/system/media/pages/setting.vue'),
      },
      {
        path: '/system/media/search',
        name: 'systemMediaSearch',
        component: () => import('../views/system/media/pages/search.vue'),
      },
      {
        path: '/system/media/logs',
        name: 'systemMediaLogs',
        component: () => import('../views/system/media/pages/logs.vue'),
      },
    ],
  },

  // 暗网
  {
    path: '/system/shenwang/index',
    name: 'shenwangIndex',
    meta: {
      name: '情报落查系统',
    },
    component: () => import('@/views/system/shenwang/Index'),
    redirect: '/system/shenwang/home',
    children: [
      {
        path: '/system/shenwang/home',
        name: 'systemshenwangHome',
        meta: {
          name: '首页',
          icon: 'el-icon-s-home',
        },
        component: () => import('../views/system/shenwang/pages/Home.vue'),
      },
      {
        path: '/system/shenwang/configuration',
        name: 'systemshenwangConfiguration',
        meta: {
          name: '配置方案',
          icon: 'el-icon-c-scale-to-original',
        },
        component: () => import('../views/system/shenwang/pages/Configuration.vue'),
      },
      {
        path: '/system/shenwang/data/:type',
        name: 'systemshenwangData',
        meta: {
          name: '落查',
          icon: 'el-icon-search',
          data: {
            path: '/system/shenwang/data/',
            type: '4-5shenwang',
          },
        },
        // component: () => import("../views/system/components/WebCheck.vue"),
        component: () => import('../views/system/shenwang/pages/Search.vue'),
      },
      {
        path: '/system/shenwang/logs',
        name: 'systemshenwangLog',
        meta: {
          name: '网站来源',
          icon: 'el-icon-files',
        },
        component: () => import('../views/system/shenwang/pages/Logs.vue'),
      },
      {
        path: '/system/shenwang/collect/:type',
        name: 'systemshenwangSetting',
        meta: {
          name: '我的收藏',
          icon: 'el-icon-star-on',
          data: {
            path: '/system/shenwang/collect/',
            type: '4-5shenwang',
          },
        },
        component: () => import('../views/system/shenwang/pages/Setting.vue'),
      },
    ],
  },
  // 图片类社交
  {
    path: '/system/img/index',
    name: 'imgIndex',
    meta: {
      name: '情报落查系统',
    },
    component: () => import('@/views/system/imgSystem/Index'),
    redirect: '/system/img/home',
    children: [
      {
        path: '/system/img/home',
        name: 'systemImgHome',
        meta: {
          name: '首页',
          icon: 'el-icon-s-home',
        },
        component: () => import('../views/system/imgSystem/pages/Home.vue'),
      },
      {
        path: '/system/img/configuration',
        name: 'systemImgConfiguration',
        meta: {
          name: '配置方案',
          icon: 'el-icon-c-scale-to-original',
        },
        component: () => import('../views/system/imgSystem/pages/Configuration.vue'),
      },
      {
        path: '/system/img/data/:type',
        name: 'systemImgData',
        meta: {
          name: '落查',
          icon: 'el-icon-search',
          data: {
            path: '/system/img/data/',
            type: '5-4ins',
          },
        },
        component: () => import('../views/system/imgSystem/pages/Search.vue'),
      },
      {
        path: '/system/img/collect/:type',
        name: 'systemImgSetting',
        meta: {
          name: '我的关注',
          icon: 'el-icon-star-on',
          data: {
            path: '/system/img/collect/',
            type: '5-4ins',
          },
        },
        component: () => import('../views/system/imgSystem/pages/Setting.vue'),
      },
    ],
  },
  // 视频社交
  {
    path: '/system/youtube/index',
    name: 'youtubeIndex',
    meta: {
      name: '视频类社交网站落查	',
    },
    component: () => import('@/views/system/youtube/Index'),
    redirect: '/system/youtube/home',
    children: [
      {
        path: '/system/youtube/home',
        name: 'systemyoutubeHome',
        meta: {
          name: '首页',
          icon: 'el-icon-s-home',
        },
        component: () => import('../views/system/youtube/pages/Home.vue'),
      },

      {
        path: '/system/youtube/data/:type',
        name: 'systemyoutubeData',
        meta: {
          name: '落查',
          icon: 'el-icon-search',
          data: {
            path: '/system/youtube/data/',
            type: '5-5youtube',
          },
        },

        component: () => import('../views/system/youtube/pages/Search.vue'),
      },

      {
        path: '/system/youtube/collect/:type',
        name: 'systemyoutubeSetting',
        meta: {
          name: '我的关注',
          icon: 'el-icon-star-on',
          data: {
            path: '/system/youtube/collect/',
            type: '5-5youtube',
          },
        },
        component: () => import('../views/system/youtube/pages/Setting.vue'),
      },
    ],
  },
  // 反华网站
  {
    path: '/system/fanhua/index',
    name: 'fanhuawangzhanIndex',
    meta: {
      name: '情报落查系统',
    },
    component: () => import('@/views/system/fanhua/Index'),
    redirect: '/system/fanhua/home',
    children: [
      {
        path: '/system/fanhua/home',
        name: 'systemfanhuawangzhanHome',
        meta: {
          name: '首页',
          icon: 'el-icon-s-home',
        },
        component: () => import('../views/system/fanhua/pages/Home.vue'),
      },
      {
        path: '/system/fanhua/configuration',
        name: 'systemfanhuawangzhanConfiguration',
        meta: {
          name: '配置方案',
          icon: 'el-icon-c-scale-to-original',
        },
        component: () => import('../views/system/fanhua/pages/Configuration.vue'),
      },
      {
        path: '/system/fanhua/data/:type',
        name: 'systemfanhuawangzhanData',
        meta: {
          name: '落查',
          icon: 'el-icon-search',
          data: {
            path: '/system/fanhua/data/',
            type: '5-5fanhuawangzhan',
          },
        },

        component: () => import('../views/system/fanhua/pages/Search.vue'),
      },
      {
        path: '/system/fanhua/logs',
        name: 'systemfanhuawangzhanLog',
        meta: {
          name: '网站来源',
          icon: 'el-icon-files',
        },
        component: () => import('../views/system/fanhua/pages/Logs.vue'),
      },
      {
        path: '/system/fanhua/collect/:type',
        name: 'systemfanhuawangzhanSetting',
        meta: {
          name: '我的关注',
          icon: 'el-icon-star-on',
          data: {
            path: '/system/fanhua/collect/',
            type: '5-5fanhuawangzhan',
          },
        },
        component: () => import('../views/system/fanhua/pages/Setting.vue'),
      },
      // {
      //   path: "/system/fanhua/result/:type",
      //   name: "systemfanhuawangzhanResult",
      //   meta: {
      //     name: "落查",
      //     icon: "el-icon-s-data",
      //     data: {
      //       path: "/system/fanhua/result/",
      //       type: "5-5fanhuawangzhan",
      //     },
      //   },
      //   component: () => import("../views/system/fanhua/pages/Result.vue"),
      // },
    ],
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})
router.beforeEach((to, form, next) => {
  // let token = sessionStorage.getItem("wfToken");

  // if (to.path == "/login" || to.path == "/entry") {
  //   next();
  // } else {
  //   if (token) {
  //     next();
  //   } else {
  //     router.push("/login");
  //   }
  // }
  next()
})

export default router
