const state = {
  fangAnList: [
    {
      a: "方案1",
      b: "Youth74950791",
      e: "2023-01-06",
    },
    {
      a: "时政",
      b: "kanfengyunguo",
      e: "2023-05-18",
    },
    {
      a: "运动",
      b: "voa197905011",
      c: "备注",
    },
    {
      a: "美食",
      b: "Kinney86611828",
    },
  ],
  shouCangList: [
    {
      create_time: "2022-12-27T05:41:12+08:00",
      create_user: "304",
      deal_next: "1",
      email: "",
      email_star: "",
      end_time: "",
      id: "16729",
      info: "",
      phone_star: "77",
      remark: "",
      s_phone: "",
      uname: "5EkxWI8hY6uleRo",
      user_web: "多维",
    },
    {
      create_time: "2022-12-27T02:44:39+08:00",
      create_user: "23",
      deal_next: "1",
      email: "",
      email_star: "ti***********@g****.***",
      end_time: "",
      id: "11917",
      info: "",
      phone_star: "26",
      remark: "",
      s_phone: "",
      uname: "Wanglisi",
      user_web: "万维",
    },
    {
      create_time: "2022-12-27T02:31:18+08:00",
      create_user: "219",
      deal_next: "1",
      email: "",
      email_star: "ku********@g****.***",
      end_time: "",
      id: "8720",
      info: "",
      phone_star: "12",
      remark: "",
      s_phone: "",
      uname: "deilaes",
      user_web: "新唐人",
    },
    {
      create_time: "2022-12-27T02:21:55+08:00",
      create_user: "219",
      deal_next: "1",
      email: "",
      email_star: "bl********@h******.***",
      end_time: "",
      id: "1601",
      info: "",
      phone_star: "",
      remark: "",
      s_phone: "",
      uname: "star01kuo",
      user_web: "阿波罗",
    },
    {
      create_time: "2022-12-27T01:52:12+08:00",
      create_user: "338",
      deal_next: "1",
      email: "",
      email_star: "ch******@g****.***",
      end_time: "",
      id: "18369",
      info: "",
      phone_star: "",
      remark: "",
      s_phone: "",
      uname: "eich_sz",
      user_web: "新唐人",
    },
    {
      create_time: "2022-12-27T00:23:37+08:00",
      create_user: "219",
      deal_next: "1",
      email: "",
      email_star: "ww*******@o******.***",
      end_time: "",
      id: "5446",
      info: "",
      phone_star: "10",
      remark: "",
      s_phone: "",
      uname: "wwwcc41781431",
      user_web: "多维",
    },
  ],
};
const mutations = {
  pushFangAn(state, obj) {
    state.fangAnList.push(obj);
  },
  delFangAn(state, i) {
    state.fangAnList.splice(i, 1);
  },
  //   收藏
  pushShouCang(state, obj) {
    state.shouCangList.push(obj);
  },
  delShouCang(state, i) {
    state.shouCangList.splice(i, 1);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
