<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "app",
  mounted() {
    this.getWindowClient();

    window.addEventListener("resize", () => {
      this.getWindowClient();
    });
  },
  methods: {
    getWindowClient() {
      let windowWidth = document.documentElement.clientWidth; //实时屏幕宽度
      let windowHeight = document.documentElement.clientHeight; //实时屏幕高度

      // this.$store.commit("SETWIDTH", windowWidth);
      this.$store.commit("SETHEIGHT", windowHeight);
    },
  },
};
</script>
<style lang="scss">
html,
body {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #e7f1ff;
}
* {
  margin: 0;
  padding: 0;
}
</style>
