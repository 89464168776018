const state = {
  fangAnList: [
    {
      title: '我的方案一(Siegler)',
      name: 'M.G. Siegler',
      keyword: 'mgs',
      twitter: 'mgsiegler',
      ins: 'mgsiegler',
    },
    {
      title: '我的方案二(Jana)',
      name: 'Jana Messerschmidt',
      keyword: 'Jana',
      twitter: 'janamal',
      ins: 'janamal',
    },
    {
      title: '我的方案三(Shaun)',
      name: 'Shaun Maguire',
      keyword: 'Shaun',
      twitter: 'shaunmmaguire',
      ins: '',
    },
    {
      title: '我的方案四(beh_zod)',
      name: 'Behzod Sirjani',
      keyword: 'mgs',
      twitter: 'mgsiegler',
      ins: 'mgsiegler',
    },
  ],
  shouCangList: [
    {
      bio: 'emmad',
      create_time: '2015-09-10T01:42:53+08:00',
      email: '',
      id: '363',
      instagram: 'emmadmazhari',
      name: 'emmad',
      photo_url: 'https://d14u0p1qkech25.cloudfront.net/429_31edf2df-c65f-45ce-854b-0df7017b268d',
      twitter: 'emmadmazhari',
      user_id: '429',
    },
    {
      bio: 'probablyharis',
      create_time: '2015-09-05T01:04:21+08:00',
      email: '',
      id: '290',
      instagram: '',
      name: 'Haris',
      photo_url: 'https://d14u0p1qkech25.cloudfront.net/349_7034d5e5-cb28-484e-bd76-29169dde87a8',
      twitter: '',
      user_id: '349',
    },
    {
      bio: 'alexk',
      create_time: '2015-09-03T23:31:41+08:00',
      email: '',
      id: '146',
      instagram: '',
      name: 'alex',
      photo_url: '',
      twitter: '',
      user_id: '170',
    },
    {
      bio: 'bennett',
      create_time: '2015-08-31T04:13:01+08:00',
      email: '',
      id: '175',
      instagram: 'bennettjames',
      name: 'Ben Mathews',
      photo_url: 'https://d14u0p1qkech25.cloudfront.net/201_55f73c05-2569-4498-be07-5c658de4b925',
      twitter: 'bentwitr',
      user_id: '201',
    },
    {
      bio: 'illscience',
      create_time: '2015-08-28T09:45:11+08:00',
      email: '',
      id: '94',
      instagram: '',
      name: 'Anish Acharya',
      photo_url: 'https://d14u0p1qkech25.cloudfront.net/112_8f088100-6d9a-46f6-92fa-0e253ead99fb',
      twitter: 'illscience',
      user_id: '112',
    },
    {
      bio: 'matthuang',
      create_time: '2015-08-24T03:40:15+08:00',
      email: '',
      id: '286',
      instagram: '',
      name: 'Matt Huang',
      photo_url: 'https://d14u0p1qkech25.cloudfront.net/344_ed1a8fad-7508-481c-8578-ce3becda19a0',
      twitter: 'matthuang',
      user_id: '344',
    },
  ],
  jiluList: ['M.G. Siegler', 'Jana Messerschmidt', 'Siobhan Quinn'],
}
const mutations = {
  pushFangAn(state, obj) {
    state.fangAnList.push(obj)
  },
  delFangAn(state, i) {
    state.fangAnList.splice(i, 1)
  },
  //   收藏
  pushShouCang(state, obj) {
    state.shouCangList.push(obj)
  },
  delShouCang(state, i) {
    state.shouCangList.splice(i, 1)
  },
  //增加检索记录
  addJilu(state, obj) {
    state.jiluList.push(obj)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}
