export function getday() {
  let days = [];
  for (let i = 0; i <= 24 * 6; i += 24) {
    //今天加上前6天
    let dateItem = new Date(new Date().getTime() - i * 60 * 60 * 1000); //使用当天时间戳减去以前的时间毫秒（小时*分*秒*毫秒）
    let y = dateItem.getFullYear(); //获取年份
    let m = dateItem.getMonth() + 1; //获取月份js月份从0开始，需要+1
    let d = dateItem.getDate(); //获取日期
    m = addDate0(m); //给为单数的月份补零
    d = addDate0(d); //给为单数的日期补零
    let valueItem = y + "-" + m + "-" + d; //组合
    days.push(valueItem); //添加至数组
  }

  return days.reverse();
}

function addDate0(time) {
  if (time.toString().length == 1) {
    time = "0" + time.toString();
  }
  return time;
}

/*
    params
    actual:实际总数
    all:要展示的总数（虚拟）
    size: 一页多少
    current:当前点击页
*/
export function changeCurrent(actual, all, size, current) {
  let page = Math.ceil(actual / size); //获取实际多少页
  let fictitious = Math.ceil(all / size); //获取虚拟多少页
  let proportion = page / fictitious; //获取实虚比例
  if (current > page) {
    // return Math.ceil(current * proportion);
    return Math.floor(current * proportion);
  } else {
    return current;
  }
}

export function getStorage(type, name, obj) {
  let data = sessionStorage.getItem(name);
  if (data) {
    if (type == "get") {
      return JSON.parse(data);
    } else {
      let a = JSON.parse(data);
      a.push(obj);
      sessionStorage.setItem(name, JSON.stringify(a));
    }
  } else {
    if (type == "set") {
      let b = [];
      b.push(obj);
      sessionStorage.setItem(name, JSON.stringify(b));
    }
  }
}
