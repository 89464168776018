import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/plugin/style.scss";
// 全局组件
import components from "@/components/index";
Vue.use(components);

// 全局方法
import * as globalFun from "@/plugin/global";
Vue.prototype.$globalFun = globalFun;

Vue.config.productionTip = false;
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
