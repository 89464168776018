const state = {
  fangAnList: [
    {
      title: "gettr相关",
      keyword: "128997277",
      creatTime: "2023-06-011",
    },
    {
      title: "github相关",
      keyword: "87185813",
      creatTime: "2023-06-12",
    },
    {
      title: "敏感",
      keyword: "86401645",
      creatTime: "2023-06-12",
    },
    {
      title: "时政",
      keyword: "140332298",
      creatTime: "2023-06-13",
    },
    {
      title: "资金",
      keyword: "141094734",
      creatTime: "2023-06-13",
    },
    {
      title: "中共",
      keyword: "93725993",
      creatTime: "2023-06-14",
    },
  ],
  shouCangList: [
    {
      create_time: "2022-04-24T15:07:04+08:00",
      id: "27622184",
      modify_time: "2016-07-18T13:33:51+08:00",
      phone: "",
      user_id: "114465354",
      user_name: "圈套丶高持",
      user_web: "github",
    },
    {
      create_time: "2022-04-23T02:38:03+08:00",
      id: "27622455",
      modify_time: "2016-01-15T16:02:28+08:00",
      phone: "",
      user_id: "108382890",
      user_name: "3053419895",
      user_web: "tumblr",
    },
    {
      create_time: "2022-04-20T13:04:13+08:00",
      id: "27621957",
      modify_time: "2019-08-02T12:30:44+08:00",
      phone: "16666325241",
      user_id: "140400382",
      user_name: "朝花夕拾h6",
      user_web: "tumblr",
    },
    {
      create_time: "2022-04-17T18:08:11+08:00",
      id: "27622073",
      modify_time: "2017-09-08T12:17:38+08:00",
      phone: "",
      user_id: "82734320",
      user_name: "訴箫",
      user_web: "gettr",
    },
    {
      create_time: "2022-04-16T07:44:31+08:00",
      id: "27622192",
      modify_time: "2018-09-21T09:49:37+08:00",
      phone: "",
      user_id: "137432146",
      user_name: "闲云锤子",
      user_web: "github",
    },
  ],
};
const mutations = {
  pushFangAn(state, obj) {
    state.fangAnList.push(obj);
  },
  delFangAn(state, i) {
    state.fangAnList.splice(i, 1);
  },
  //   收藏
  pushShouCang(state, obj) {
    state.shouCangList.push(obj);
  },
  delShouCang(state, i) {
    state.shouCangList.splice(i, 1);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
