import request from "@/plugin/axios";
import requestFB from "@/plugin/axiosFB";
import requestData from "@/plugin/axiosPage";
import requestNum from "@/plugin/axiosNum";

export function resourcesApi(keyWords) {
  return request({
    url: "/gfdetfgerr54214d/get_es_bd_by_name.php?name=" + keyWords,
    method: "GET",
  });
}
export function loginApi(data) {
  return requestFB({
    url: "/authentication/login",
    method: "post",
    data,
  });
}

//任务提交
export function setTask(data) {
  return requestFB({
    url: `/investigation/tasks`,
    method: "post",
    data,
  });
}
// 落查 查询记录
export function logTask(data) {
  return requestFB({
    url: `/investigation/tasks`,
    method: "get",
    params: data,
  });
}

// 根据id获取任务详情
export function logDetail(id) {
  return requestFB({
    url: `/investigation/tasks/${id}`,
    method: "get",
  });
}

// 首页头部数据
export function statistics() {
  return requestFB({
    url: "/dashboard/dashboard/summary",
    method: "get",
  });
}
// 近7天
export function collectionAmount() {
  return requestFB({
    url: "/dashboard/dashboard/investigation",
    method: "get",
  });
}
export function getScheme(data) {
  return requestFB({
    url: `/scheme/collections`,
    method: "get",
    params: data,
  });
}
export function facebookList(data) {
  return requestFB({
    url: `/facebook/users`,
    method: "get",
    params: data,
  });
}

export function countChangeLogs(data) {
  return requestFB({
    url: `/investigation/countChangeLogs`,
    method: "get",
    params: data,
  });
}
export function articlesList(data) {
  return requestFB({
    url: `/facebook/articles`,
    method: "get",
    params: data,
  });
}

export function bigDataApi(data) {
  return requestData({
    url: `/page`,
    method: "post",
    data,
  });
}

export function bigData2Api(data) {
  return requestData({
    url: `/page2`,
    method: "post",
    data,
  });
}

export function numApi(id) {
  return requestNum({
    url: `/statistics/${id}`,
  });
}

export function bigData5Api(data) {
  return requestData({
    url: `/page5`,
    method: "post",
    data,
  });
}

// 人口数据
export function bigData6Api(data) {
  return requestData({
    url: `/page4`,
    method: "post",
    data,
  });
}
// facebook采集
export function bigData7Api(data) {
  return requestData({
    url: `/page6`,
    method: "post",
    data,
  });
}
// 提交任务
export function addTaskApi(data) {
  return requestData({
    url: `/add`,
    method: "post",
    data,
  });
}
