const state = {
  fangAnList: [
    {
      a: "娱乐圈",
      b: "33645774",
      c: "备注",
    },
    {
      a: "时政",
      b: "33645879",
      c: "2023-05-18",
    },
    {
      a: "消息ID",
      b: "33646002",
    },
  ],
  shouCangList: [
    {
      channel_id: "halleimer",
      create_time: "2020-09-29T23:42:30+08:00",
      gmail: "",
      id: "1338",
      reason: "",
      status: "0",
      user_id: "537",
    },
    {
      channel_id: "UCThvTxQ-zF_ENso9LD_uHOQ",
      create_time: "2020-09-29T16:13:40+08:00",
      gmail: "",
      id: "454",
      reason: "",
      status: "2",
      user_id: "168",
    },
    {
      channel_id: "UCcWBxfaO69GPOFHSArNET2Q",
      create_time: "2022-06-23T06:50:30+08:00",
      gmail: "huashuohanguo@gmail.com",
      id: "495",
      reason: "",
      status: "1",
      user_id: "",
    },
    {
      channel_id: "UCoJF8WbNcNestZolm5HVbrA",
      create_time: "2020-09-29T08:54:33+08:00",
      gmail: "",
      id: "13",
      reason: "",
      status: "2",
      user_id: "1",
    },
    {
      channel_id: "UCfb1VoSCsIS7kIXMTYNhvXw",
      create_time: "2020-09-28T23:22:08+08:00",
      gmail: "",
      id: "114",
      reason: "",
      status: "2",
      user_id: "46",
    },
    {
      channel_id: "UCUl9e_jmYcBXRP_KB9mWcpQ",
      create_time: "2020-09-27T23:03:59+08:00",
      gmail: "",
      id: "254",
      reason: "",
      status: "2",
      user_id: "91",
    },
  ],
};
const mutations = {
  pushFangAn(state, obj) {
    state.fangAnList.push(obj);
  },
  delFangAn(state, i) {
    state.fangAnList.splice(i, 1);
  },
  //   收藏
  pushShouCang(state, obj) {
    state.shouCangList.push(obj);
  },
  delShouCang(state, i) {
    state.shouCangList.splice(i, 1);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
