import Vue from "vue";
import Vuex from "vuex";
import shenwang from "./modul/shenwang.js";
import imgSystem from "./modul/imgSystem.js";
import youtube from "./modul/youtube.js";
import fanhua from "./modul/fanhua.js";
import face from "./modul/face.js";
import line from "./modul/line.js";
import media from "./modul/media.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    routerList: [],
    windowHeight: 0,
    titleObj: {},
    pptItem: {},
  },
  getters: {},
  mutations: {
    SETHEIGHT(state, data) {
      state.windowHeight = data;
    },
    getRouter(state) {
      let data = sessionStorage.getItem("ppt_childRoter");
      if (data) {
        state.routerList = JSON.parse(data);
      }
    },
    getTitleItem(state) {
      let data = sessionStorage.getItem("JASystemJk");
      if (data) {
        state.titleObj = JSON.parse(data);
      }
    },
    getPpt_item(state) {
      let data = sessionStorage.getItem("ppt_item");
      if (data) {
        state.pptItem = JSON.parse(data);
      }
    },
  },
  actions: {},
  modules: {
    shenwang,
    imgSystem,
    youtube,
    fanhua,
    face,
    line,
    media,
  },
});
