const state = {
  fangAnList: [
    {
      title: '上海相关',
      keyword: '100015912602615',
      creatTime: '2023-05-05',
    },
    {
      title: '敏感话题',
      keyword: '100018850068153',
      creatTime: '2023-05-05',
    },
    {
      title: '深圳',
      keyword: '100002649525196',
      creatTime: '2023-05-06',
    },
    {
      title: '港澳',
      keyword: '100011348471584',
      creatTime: '2023-05-07',
    },
  ],
  shouCangList: [
    {
      create_time: '2022-07-11T01:22:11+08:00',
      id: '19703',
      user_addr: '',
      user_id: '100024990240815',
      user_ming: '郭',
      user_phone: '',
      user_sex: 'male',
      user_xing: '晓铭',
    },
    {
      create_time: '2022-07-11T01:10:50+08:00',
      id: '37775',
      user_addr: 'Wenzhou, China',
      user_id: '100010583774482',
      user_ming: 'Allen',
      user_phone: '',
      user_sex: 'male',
      user_xing: 'Zhang',
    },
    {
      create_time: '2022-07-10T05:50:03+08:00',
      id: '37214',
      user_addr: 'Shenzhen, Guangdong',
      user_id: '100001357492349',
      user_ming: 'Yeung',
      user_phone: '',
      user_sex: 'female',
      user_xing: 'Ayu',
    },
    {
      create_time: '2022-07-07T21:41:28+08:00',
      id: '7944',
      user_addr: 'Xuchang',
      user_id: '100006665768884',
      user_ming: '高',
      user_phone: '8618352084398',
      user_sex: 'male',
      user_xing: '世亭',
    },
  ],
  jiluList: ['100014269033827', '100007091290351', '100015279452622', '100026412589329'],
}
const mutations = {
  pushFangAn(state, obj) {
    state.fangAnList.push(obj)
  },
  delFangAn(state, i) {
    state.fangAnList.splice(i, 1)
  },
  //   收藏
  pushShouCang(state, obj) {
    state.shouCangList.push(obj)
  },
  delShouCang(state, i) {
    state.shouCangList.splice(i, 1)
  },
  //添加检索记录
  addjilu(state, obj) {
    state.jiluList.push(obj)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}
