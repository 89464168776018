<template>
  <el-dialog
    append-to-body
    title="查询数据"
    :visible.sync="dialogVisible"
    width="40%"
    v-loading="loading"
    element-loading-text="加载中..."
  >
    <el-table :data="tableData" height="500" style="width: 100%">
      <el-table-column label="搜索词">
        <template slot-scope="scope">
          {{ scope.row[2] }}
        </template>
      </el-table-column>
      <el-table-column label="电话">
        <template slot-scope="scope">
          {{ scope.row[3] }}
        </template>
      </el-table-column>
      <el-table-column label="时间">
        <template slot-scope="scope">
          {{ getTime() }}
        </template>
      </el-table-column>
    </el-table>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { resourcesApi } from "@/plugin/api";
export default {
  data() {
    return {
      dialogVisible: false,
      tableData: [],
      loading: false,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getData(keyWords) {
      (this.dialogVisible = true), (this.loading = true);
      resourcesApi(keyWords).then((data) => {
        this.tableData = data.rows;
        this.loading = false;
      });
    },
    getTime() {
      var myDate = new Date();
      var myYear = myDate.getFullYear(); //获取完整的年份(4位,1970-????)
      var myMonth = myDate.getMonth() + 1; //获取当前月份(0-11,0代表1月)
      var myToday = myDate.getDate(); //获取当前日(1-31)
      var myDay = myDate.getDay(); //获取当前星期X(0-6,0代表星期天)
      var myHour = myDate.getHours(); //获取当前小时数(0-23)
      var myMinute = myDate.getMinutes(); //获取当前分钟数(0-59)
      var mySecond = myDate.getSeconds(); //获取当前秒数(0-59)
      var week = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];

      let aaa =
        myYear +
        "-" +
        this.fillZero(myMonth) +
        "-" +
        this.fillZero(myToday) +
        " " +
        this.fillZero(myHour) +
        ":" +
        this.fillZero(myMinute) +
        ":" +
        this.fillZero(mySecond);
      return aaa;
    },
    fillZero(str) {
      var realNum;
      if (str < 10) {
        realNum = "0" + str;
      } else {
        realNum = str;
      }
      return realNum;
    },
  },
};
</script>

<style lang="scss" scoped></style>
