const state = {
  fangAnList: [
    {
      a: "娱乐圈",
      b: "33645774",
      c: "备注",
    },
    {
      a: "时政",
      b: "33645879",
      c: "2023-05-18",
    },
    {
      a: "消息ID",
      b: "33646002",
    },
  ],
  shouCangList: [
    {
      create_time: "2023-03-02T16:12:59+08:00",
      id: "8078496",
      message_date: "2023-03-02T15:30:58+08:00",
      message_from_id: "775325465",
      message_id: "33645559",
      message_text: "韩国",
      message_to_id: "1365760812",
      synchronize: "1",
      user_web: "Kilos",
    },
    {
      create_time: "2023-03-02T16:12:59+08:00",
      id: "8078497",
      message_date: "2023-03-02T15:30:58+08:00",
      message_from_id: "401234709",
      message_id: "33645558",
      message_text:
        "[广告] 🅾️狗推交流福利中心-【志宏集团大数据】—运营商精准数据—-海外数据渗透，精聊一条龙\n\n1. 👥 拳交精盆公测 - 792\n2. 👥 变态人妖拳交 - 2.6 K\n3. 📢 字母圈大佬之家调教—捆绑—鞭打—滴蜡—窒... - 1.5 K\n4. 👥 变态 口交 拳交射在嘴巴里 - 257\n5. 👥 厕所 拳交 变态 - 1.1 K\n6. 👥 韩舞 拳交 主奴 - 252\n7. 👥 自慰拳交扩张自拍韩国n号视频爱好群 - 3.9 K\n8. 👥 拳交 性虐 秀场 - 229\n9. 📢 自慰拳交扩张稀有视频预览频道 - 35.6 K\n10. 📢 医疗调教虐阴 - 769\n11. 👥 美女丝袜丝足街拍偷拍厕拍WC3D动画写真... - 3.5 K\n12. 👥 重口味—肛交 - 51\n13. 👥 原神一只霸王兽娜娜 螺肉 - 9\n14. 👥 日韩 拳交 舔阴 - 150\n15. 👥 阴道扩张拳交 - 574\n16. 📢 车祸拳交电影 恐怖 恶心 - 62\n17. 👥 老师母狗自拍 坊子 - 21\n18. 👥 【小收藏】福利预览群抖音快手虎牙斗鱼推特... - 24.8 K\n19. 📢 深喉妈妈《百合蕾丝》拳交骚逼《鲍鱼高潮》 - 90\n20. 📢 虐阴调教自慰拳交扩张 - 824\n\n👮‍♂️ 提示：如何举报一个链接？",
      message_to_id: "1365760812",
      synchronize: "0",
      user_web: "Kilos",
    },
    {
      create_time: "2023-03-02T16:12:48+08:00",
      id: "8078495",
      message_date: "2023-03-02T15:30:59+08:00",
      message_from_id: "401234709",
      message_id: "33645560",
      message_text:
        "[广告] 【财源官方跑分群】🔥🔥🔥进群日入十万🔥🔥🔥\n\n21. 📢 韩国主播bj - 68.8 K\n22. 📢 【韩国】【重口】 - 1.2 K\n23. 👥 韩国买币 进 - 176\n24. 📢 【韩国】高清 - 47\n25. 👥 {韩国数据} - 222\n26. 📢 韩国bj主播直播抖音裸舞 - 89.9 K\n27. 📢 韩国【美女】 - 87\n28. 📢 韩国-日本 - 2.3 K\n29. 👥 韩国美女走光 - 2.7 K\n30. 📢 韩国 理论 - 96\n31. 📢 韩国裸舞直播 - 769\n32. 📢 韩国台湾国产主播 - 17.6 K\n33. 👥 【韩国通道】】 - 178\n34. 📢 韩国【性x】 - 54\n35. 👥 韩国 直播 射屏 - 246\n36. 📢 韩国女主播 - 11.1 K\n37. 👥 韩国秘密福利 - 1.2 K\n38. 📢 韩国裸舞精选 - 4.6 K\n39. 📢 韩国-东京热 - 46\n40. 📢 【韩国】漫画 - 65\n\n👮‍♂️ 提示：如何举报一个链接？",
      message_to_id: "1365760812",
      synchronize: "0",
      user_web: "Kilos",
    },
    {
      create_time: "2023-03-02T16:12:47+08:00",
      id: "8078494",
      message_date: "2023-03-02T15:31:01+08:00",
      message_from_id: "1956827659",
      message_id: "33645561",
      message_text: "乱伦",
      message_to_id: "1365760812",
      synchronize: "1",
      user_web: "Kilos",
    },
    {
      create_time: "2023-03-02T16:12:42+08:00",
      id: "9718062",
      message_date: "2023-03-02T13:30:14+08:00",
      message_from_id: "",
      message_id: "175863",
      message_text:
        "人社部谈个人养老金：适时调整缴费上限，参加人数已达2817万人\n国新办举行“权威部门话开局”系列主题新闻发布会，介绍就业和社会保障工作情况，人力资源和社会保障部副部长李忠表示，将会适时调整个人养老金缴费上限 ... 人社部牵头建设了个人养老金信息管理服务平台，在这个平台上，为每一位参加人建立了唯一的个人养老金账户，用于信息记录、查询和服务等 ... 参加人可以直观掌握自己个人养老金的运行情况，参加人可以通过国家社会保险公共服务平台、电子社保卡等入口直接登陆个人养老金信息管理服务平台，可以通过商业银行渠道开设账户、缴费并购买金融产品。\n\n相关事件\n人社部谈个人养老金：适时调整缴费上限，参加人数已达2817万人  2023-03-02\n人社部：个人养老金需开设两个账户，参加人可在商业银行一次性开设  2022-04-25\n\nhttps://www.thepaper.cn/newsDetail_forward_22138126\n\n#热门话题",
      message_to_id: "1097926420",
      synchronize: "1",
      user_web: "Kilos",
    },
    {
      create_time: "2023-03-02T16:12:42+08:00",
      id: "9718063",
      message_date: "2023-03-02T13:30:13+08:00",
      message_from_id: "",
      message_id: "175862",
      message_text:
        "瑞信：维持和黄医药“跑赢大市”评级，目标价37.2港元\n该行指出，公司2022财年肿瘤/免疫业务综合收入达到1.64亿美元，同比增长37%，略低于该行预期，但符合1.6-1.9亿美元的指引 ... 展望2023财年，管理层指引肿瘤业务综合收入为4.5-5亿美元，包括部分确认武田（Takeda）4亿美元预付款 ... 也指引2023财年的肿瘤药物销售将出现类似于2022财年63%的增长，高于该行预期。\n\n相关事件\n瑞信：维持和黄医药“跑赢大市”评级，目标价37.2港元  2023-03-02\n瑞信：维持石药集团“跑赢大市”评级，目标价升至14港元  2021-05-25\n瑞信：下调舜宇光学科技目标价至168港元，维持跑赢大市评级  2020-08-20\n郭明錤：舜宇光学2021年将进入苹果镜头供应链  2020-07-22\n\nhttps://36kr.com/newsflashes/2153850536038663?f=rss\n\n#热门话题",
      message_to_id: "1097926420",
      synchronize: "1",
      user_web: "Kilos",
    },
  ],
};
const mutations = {
  pushFangAn(state, obj) {
    state.fangAnList.push(obj);
  },
  delFangAn(state, i) {
    state.fangAnList.splice(i, 1);
  },
  //   收藏
  pushShouCang(state, obj) {
    state.shouCangList.push(obj);
  },
  delShouCang(state, i) {
    state.shouCangList.splice(i, 1);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
