import axios from "axios";
import { Message } from "element-ui";
// import { MessageBox, Message } from 'element-ui'
import store from "@/store";

import Router from "../router/index.js";

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_APIFB,
  timeout: 30000, // request timeout
});
service.interceptors.request.use(
  (config) => {
    config.headers["X-CSRFToken"] = getCookie("csrftoken");
    return config;
  },
  (error) => {
    console.log(error); // for debug
    return Promise.reject(error);
  }
);
service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error);
  }
);
function getCookie(cookie_name) {
  var allcookies = document.cookie;
  //索引长度，开始索引的位置
  var cookie_pos = allcookies.indexOf(cookie_name);

  // 如果找到了索引，就代表cookie存在,否则不存在
  if (cookie_pos != -1) {
    // 把cookie_pos放在值的开始，只要给值加1即可
    //计算取cookie值得开始索引，加的1为“=”
    cookie_pos = cookie_pos + cookie_name.length + 1;

    //计算取cookie值得结束索引
    var cookie_end = allcookies.indexOf(";", cookie_pos);

    if (cookie_end == -1) {
      cookie_end = allcookies.length;
    }
    //得到想要的cookie的值
    // var value = unescape(allcookies.substring(cookie_pos, cookie_end))
    var value = allcookies.substring(cookie_pos, cookie_end);
  }
  return value;
}

export default service;
