const state = {
  fangAnList: [
    {
      title: "测试数据",
      name: "zhaovessel",
      memo: "这里是备注",
      time: "2022-12-08 15:32:24",
    },
    {
      title: "方案1",
      name: "zhejiangyiwu",
      memo: "这里是备注",
      time: "2022-12-08 15:32:24",
    },
  ],
  shouCangList: [
    {
      create_time: "2020-03-06T21:29:35+08:00",
      id: "207",
      user_avatar:
        "http://pbs.twimg.com/profile_images/894105492565368832/KKX1RiMU_normal.jpg",
      user_avatar_qiniu: "",
      user_create_time: "2013-08-03T18:28:27+08:00",
      user_description: "",
      user_email: "",
      user_id: "1643398226",
      user_name: "廿九",
      user_screen_name: "dulin29",
      user_update_time: "2018-03-20T15:24:06+08:00",
      user_web: "Instagram",
    },
    {
      create_time: "2020-03-04T20:59:02+08:00",
      id: "657",
      user_avatar:
        "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
      user_avatar_qiniu: "",
      user_create_time: "2017-09-03T04:35:37+08:00",
      user_description: "",
      user_email: "",
      user_id: "904201188291231744",
      user_name: "代星星",
      user_screen_name: "daixingxing",
      user_update_time: "2018-03-20T15:20:56+08:00",
      user_web: "Instagram",
    },
    {
      create_time: "2020-02-28T03:44:57+08:00",
      id: "479",
      user_avatar:
        "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
      user_avatar_qiniu: "",
      user_create_time: "2017-07-03T17:02:32+08:00",
      user_description: "没有",
      user_email: "",
      user_id: "881921108672303104",
      user_name: "黄哲伦",
      user_screen_name: "tRudGx2JXX5n557",
      user_update_time: "2018-03-20T15:17:57+08:00",
      user_web: "Instagram",
    },
    {
      create_time: "2020-02-27T01:03:31+08:00",
      id: "546",
      user_avatar:
        "http://pbs.twimg.com/profile_images/3052405757/8506523068f4f49275d629746af30472_normal.jpeg",
      user_avatar_qiniu: "",
      user_create_time: "2010-08-28T09:15:41+08:00",
      user_description: "梦想像断了绳子的风筝，无从抓起。",
      user_email: "",
      user_id: "183956849",
      user_name: "潘湿胸",
      user_screen_name: "panpengfei",
      user_update_time: "2018-03-20T15:23:34+08:00",
      user_web: "Instagram",
    },
    {
      create_time: "2020-02-26T17:19:59+08:00",
      id: "63",
      user_avatar:
        "http://pbs.twimg.com/profile_images/898359527052943360/eTJX6HzY_normal.jpg",
      user_avatar_qiniu: "",
      user_create_time: "2014-06-08T12:23:23+08:00",
      user_description: "不忘初心 马上行动",
      user_email: "",
      user_id: "2554624741",
      user_name: "耳鸣惊人",
      user_screen_name: "492529119",
      user_update_time: "2018-03-20T15:20:05+08:00",
      user_web: "Instagram",
    },
    {
      create_time: "2020-02-18T11:07:00+08:00",
      id: "626",
      user_avatar:
        "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
      user_avatar_qiniu: "",
      user_create_time: "2017-09-17T15:17:37+08:00",
      user_description: "",
      user_email: "",
      user_id: "909436185361006593",
      user_name: "自在难得",
      user_screen_name: "KMuvtP4z8P5vAJI",
      user_update_time: "2018-03-20T15:17:55+08:00",
      user_web: "Instagram",
    },
  ],
};
const mutations = {
  pushFangAn(state, obj) {
    state.fangAnList.push(obj);
  },
  delFangAn(state, i) {
    state.fangAnList.splice(i, 1);
  },
  //   收藏
  pushShouCang(state, obj) {
    state.shouCangList.push(obj);
  },
  delShouCang(state, i) {
    state.shouCangList.splice(i, 1);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
