import axios from "axios";
import { Message } from "element-ui";
// import { MessageBox, Message } from 'element-ui'
import store from "@/store";

import Router from "../router/index.js";

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_APIDATA,
  timeout: 30000 * 4, // request timeout
});

service.interceptors.response.use(
  (response) => {
    const { data } = response;
    if (data.code == 200) {
      return data;
    } else {
      Message.error("请求错误！");
    }
  },
  (error) => {
    console.log(error);
    Message.error("error");
  }
);

export default service;
